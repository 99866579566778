<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path d="m6 1.5 0 10" class="a"></path>
        <path d="m4 2.5 2 2 2 -2" class="a"></path>
        <path d="m4 10.5 2 -2 2 2" class="a"></path>
        <path d="m1 6.5 10 0" class="a"></path>
        <path d="m2 8.5 2 -2 -2 -2" class="a"></path>
        <path d="m10 8.5 -2 -2 2 -2" class="a"></path>
        <path
            d="M11.5 9h1.323a1 1 0 0 1 0.928 0.629L15.5 14l2.775 0.793a1 1 0 0 1 0.725 0.961V19a1 1 0 0 1 -1 1h-1.063"
            class="a"
        ></path>
        <path
            d="M3.063 20H2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h6v-1"
            class="a"
        ></path>
        <path d="M3 20.5a2 2 0 1 0 4 0 2 2 0 1 0 -4 0" class="a"></path>
        <path d="M13 20.5a2 2 0 1 0 4 0 2 2 0 1 0 -4 0" class="a"></path>
        <path d="M23 11.5s-2 1 -2 5.5 2 5 2 5" class="a"></path>
        <path d="m19 17.5 2 0" class="a"></path>
        <path d="m10.2 11 0.12 1.2a2 2 0 0 0 1.99 1.8h3.19" class="a"></path>
        <path d="m6.937 20 6.126 0" class="a"></path>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'SnowVehicleIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
