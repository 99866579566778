<template>
    <MeasurementItem
        :datetime="lastUpdate"
        :link-to="
            spreadDensitySalt && spreadDensitySand
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: tracker.id,
                      },
                      query: {
                          assetType: 'salt',
                          measurement: [
                              spreadDensitySalt.key,
                              spreadDensitySand.key,
                          ],
                      },
                  }
                : spreadDensitySalt
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: tracker.id,
                      },
                      query: {
                          assetType: 'salt',
                          measurement: spreadDensitySalt.key,
                      },
                  }
                : null
        "
    >
        <template #icon>
            <FilledCircleIcon
                v-if="spreadingOn"
                width="24"
                height="24"
                :class="spreadingOn.value ? 'active' : 'inactive'"
            />
            <SnowVehicleIcon v-else width="24" height="24" />
        </template>

        <template #content>
            <div class="l-full-width no-padding">
                <h3 class="padding-bottom no-padding">
                    {{
                        spreadingOn
                            ? $t(
                                  spreadingOn.value
                                      ? 'spreading'
                                      : 'notSpreading'
                              )
                            : $t('saltWidgetTitle')
                    }}
                </h3>

                <div
                    class="space-between"
                    :title="massSalt ? massSalt.title : null"
                >
                    <span> {{ $t('shared.measurements.mass_salt') }}: </span>

                    <h3 :class="{ 'sensor-data-mass': massSalt }">
                        {{ massSalt ? massSalt.value : '-' }}
                    </h3>
                </div>

                <div
                    class="space-between"
                    :title="massSand ? massSand.title : null"
                >
                    <span> {{ $t('shared.measurements.mass_sand') }}: </span>

                    <h3 :class="{ 'sensor-data-mass': massSand }">
                        {{ massSand ? massSand.value : '-' }}
                    </h3>
                </div>

                <div
                    class="space-between"
                    :title="massBrine ? massBrine.title : null"
                >
                    <span> {{ $t('shared.measurements.mass_brine') }}: </span>

                    <h3 :class="{ 'sensor-data-mass': massBrine }">
                        {{ massBrine ? massBrine.value : '-' }}
                    </h3>
                </div>

                <div
                    class="space-between"
                    :title="spreadDensitySalt ? spreadDensitySalt.title : null"
                >
                    <span>
                        {{ $t('shared.measurements.spread_density_salt') }}:
                    </span>

                    <h3
                        :class="{
                            'sensor-data-spread-density': spreadDensitySalt,
                        }"
                    >
                        {{ spreadDensitySalt ? spreadDensitySalt.value : '-' }}
                    </h3>
                </div>

                <div
                    class="space-between"
                    :title="spreadDensitySand ? spreadDensitySand.title : null"
                >
                    <span>
                        {{ $t('shared.measurements.spread_density_sand') }}:
                    </span>

                    <h3
                        :class="{
                            'sensor-data-spread-density': spreadDensitySand,
                        }"
                    >
                        {{ spreadDensitySand ? spreadDensitySand.value : '-' }}
                    </h3>
                </div>

                <div
                    class="space-between"
                    :title="spreadWidth ? spreadWidth.title : null"
                >
                    <span> {{ $t('shared.measurements.spread_width') }}: </span>

                    <h3 :class="{ 'sensor-data-distance': spreadWidth }">
                        {{ spreadWidth ? spreadWidth.value : '-' }}
                    </h3>
                </div>

                <div
                    class="space-between"
                    :title="spreadDistance ? spreadDistance.title : null"
                >
                    <span>
                        {{ $t('shared.measurements.spread_distance') }}:
                    </span>

                    <h3 :class="{ 'sensor-data-distance-km': spreadDistance }">
                        {{ spreadDistance ? spreadDistance.value : '-' }}
                    </h3>
                </div>

                <div
                    class="space-between l-center-v l-gap-0"
                    :title="spreadRunningTime ? spreadRunningTime.title : null"
                >
                    <span>
                        {{ $t('shared.measurements.spread_running_time') }}:
                    </span>

                    <h3>
                        {{
                            spreadRunningTime
                                ? spreadRunningTime.textDuration
                                : '-'
                        }}
                    </h3>
                </div>
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import { formatHelper, measurementHelper } from '../utils'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import SnowVehicleIcon from './icons/SnowVehicleIcon'
import moment from 'moment-timezone'

export default {
    name: 'SaltSpreadingMeasurementFragment',
    components: {
        FilledCircleIcon,
        MeasurementItem,
        SnowVehicleIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        lastUpdate() {
            let lastUpdates = [
                ...(this.massBrine ? [moment(this.massBrine.lastUpdated)] : []),
                ...(this.massSalt ? [moment(this.massSalt.lastUpdated)] : []),
                ...(this.massSand ? [moment(this.massSand.lastUpdated)] : []),
                ...(this.spreadDensitySalt
                    ? [moment(this.spreadDensitySalt.lastUpdated)]
                    : []),
                ...(this.spreadDensitySand
                    ? [moment(this.spreadDensitySand.lastUpdated)]
                    : []),
                ...(this.spreadDistance
                    ? [moment(this.spreadDistance.lastUpdated)]
                    : []),
                ...(this.spreadRunningTime
                    ? [moment(this.spreadRunningTime.lastUpdated)]
                    : []),
                ...(this.spreadWidth
                    ? [moment(this.spreadWidth.lastUpdated)]
                    : []),
                ...(this.spreadingOn
                    ? [moment(this.spreadingOn.lastUpdated)]
                    : []),
            ]
            if (lastUpdates) {
                console.log(lastUpdates)
                console.log(moment.max(...lastUpdates).toISOString())
                return moment.max(...lastUpdates).toISOString()
            }
            return null
        },
        massBrine() {
            return this.getMeasurementItem('mass_brine') || false
        },
        massSalt() {
            return this.getMeasurementItem('mass_salt') || false
        },
        massSand() {
            return this.getMeasurementItem('mass_sand') || false
        },
        spreadDensitySalt() {
            return this.getMeasurementItem('spread_density_salt') || false
        },
        spreadDensitySand() {
            return this.getMeasurementItem('spread_density_sand') || false
        },
        spreadDistance() {
            const item = this.getMeasurementItem('spread_distance')
            if (item) {
                item.value = measurementHelper.converters.odometer(item.value)
            }
            return item || false
        },
        spreadRunningTime() {
            const item = this.getMeasurementItem('spread_running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item || false
        },
        spreadWidth() {
            return this.getMeasurementItem('spread_width') || false
        },
        spreadingOn() {
            return this.getMeasurementItem('spreading_on') || false
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "notSpreading": "Salt spreading inactive",
            "spreading": "Salt spreading active",
            "saltWidgetTitle": "Salt spreading"
        },
        "de": {
            "notSpreading": "Salzstreuung inaktiv",
            "spreading": "Salzstreuung aktiv",
            "saltWidgetTitle": "Salzstreuung"
        },
        "fr": {
            "notSpreading": "Epandage de sel inactif",
            "spreading": "Epandage de sel actif",
            "saltWidgetTitle": "Epandage de sel"
        },
        "it": {
            "notSpreading": "Spargimento di sale inattivo",
            "spreading": "Spargimento di sale attivo",
            "saltWidgetTitle": "Spargimento di sale"
        }
    }
    </i18n>

<style lang="scss" scoped>
.space-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 25px;
}

.padding-bottom {
    padding-bottom: 0.35em;
}

.active {
    color: $color-green;
}

.inactive {
    color: $color-red;
}
</style>
