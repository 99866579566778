var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MeasurementItem',{attrs:{"datetime":_vm.lastUpdate,"link-to":_vm.spreadDensitySalt && _vm.spreadDensitySand
            ? {
                  name: 'chartsCombined',
                  params: {
                      id: _vm.tracker.id,
                  },
                  query: {
                      assetType: 'salt',
                      measurement: [
                          _vm.spreadDensitySalt.key,
                          _vm.spreadDensitySand.key ],
                  },
              }
            : _vm.spreadDensitySalt
            ? {
                  name: 'chartsCombined',
                  params: {
                      id: _vm.tracker.id,
                  },
                  query: {
                      assetType: 'salt',
                      measurement: _vm.spreadDensitySalt.key,
                  },
              }
            : null},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.spreadingOn)?_c('FilledCircleIcon',{class:_vm.spreadingOn.value ? 'active' : 'inactive',attrs:{"width":"24","height":"24"}}):_c('SnowVehicleIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"l-full-width no-padding"},[_c('h3',{staticClass:"padding-bottom no-padding"},[_vm._v(" "+_vm._s(_vm.spreadingOn ? _vm.$t( _vm.spreadingOn.value ? 'spreading' : 'notSpreading' ) : _vm.$t('saltWidgetTitle'))+" ")]),_c('div',{staticClass:"space-between",attrs:{"title":_vm.massSalt ? _vm.massSalt.title : null}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('shared.measurements.mass_salt'))+": ")]),_c('h3',{class:{ 'sensor-data-mass': _vm.massSalt }},[_vm._v(" "+_vm._s(_vm.massSalt ? _vm.massSalt.value : '-')+" ")])]),_c('div',{staticClass:"space-between",attrs:{"title":_vm.massSand ? _vm.massSand.title : null}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('shared.measurements.mass_sand'))+": ")]),_c('h3',{class:{ 'sensor-data-mass': _vm.massSand }},[_vm._v(" "+_vm._s(_vm.massSand ? _vm.massSand.value : '-')+" ")])]),_c('div',{staticClass:"space-between",attrs:{"title":_vm.massBrine ? _vm.massBrine.title : null}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('shared.measurements.mass_brine'))+": ")]),_c('h3',{class:{ 'sensor-data-mass': _vm.massBrine }},[_vm._v(" "+_vm._s(_vm.massBrine ? _vm.massBrine.value : '-')+" ")])]),_c('div',{staticClass:"space-between",attrs:{"title":_vm.spreadDensitySalt ? _vm.spreadDensitySalt.title : null}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('shared.measurements.spread_density_salt'))+": ")]),_c('h3',{class:{
                        'sensor-data-spread-density': _vm.spreadDensitySalt,
                    }},[_vm._v(" "+_vm._s(_vm.spreadDensitySalt ? _vm.spreadDensitySalt.value : '-')+" ")])]),_c('div',{staticClass:"space-between",attrs:{"title":_vm.spreadDensitySand ? _vm.spreadDensitySand.title : null}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('shared.measurements.spread_density_sand'))+": ")]),_c('h3',{class:{
                        'sensor-data-spread-density': _vm.spreadDensitySand,
                    }},[_vm._v(" "+_vm._s(_vm.spreadDensitySand ? _vm.spreadDensitySand.value : '-')+" ")])]),_c('div',{staticClass:"space-between",attrs:{"title":_vm.spreadWidth ? _vm.spreadWidth.title : null}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('shared.measurements.spread_width'))+": ")]),_c('h3',{class:{ 'sensor-data-distance': _vm.spreadWidth }},[_vm._v(" "+_vm._s(_vm.spreadWidth ? _vm.spreadWidth.value : '-')+" ")])]),_c('div',{staticClass:"space-between",attrs:{"title":_vm.spreadDistance ? _vm.spreadDistance.title : null}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('shared.measurements.spread_distance'))+": ")]),_c('h3',{class:{ 'sensor-data-distance-km': _vm.spreadDistance }},[_vm._v(" "+_vm._s(_vm.spreadDistance ? _vm.spreadDistance.value : '-')+" ")])]),_c('div',{staticClass:"space-between l-center-v l-gap-0",attrs:{"title":_vm.spreadRunningTime ? _vm.spreadRunningTime.title : null}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('shared.measurements.spread_running_time'))+": ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.spreadRunningTime ? _vm.spreadRunningTime.textDuration : '-')+" ")])])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }